import React from "react"
import styled from "styled-components"
import Login from "../components/login"
import SEO from "../components/seo"

const StyledContainer = styled.div` 
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem auto;
`;

export default function AuthorisePage() {
  return (
    <>
    <SEO title="Login" />
    <StyledContainer>
      <Login />
    </StyledContainer>
    </>
  )
}