import React, { useRef, useState } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { useAuth } from "../contexts/authcontext"
import { Link, navigate } from "gatsby"
import Modal from "react-modal"

export default function Login() {
  const emailRef = useRef()
  const passwordRef = useRef()
  const { login } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const closeModal = () => {
    setModalIsOpen(false)
  }

  const openModal = () => {
    setModalIsOpen(true)
  }

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      setError("")
      setLoading(true)
      await login(emailRef.current.value, passwordRef.current.value)
      setTimeout(() => {
        navigate("/purchase")
      }, 1700)
    } catch {
      setError("Failed to log in")
    }

    setLoading(false)
  }

  return (
    <>
      <Card className="w-100 mt-3" style={{ maxWidth: `45rem` }}>
        <Form
          name="loginform"
          onSubmit={handleSubmit}
          style={{ background: `transparent`, border: `none`, padding: `0` }}
        >
          <Card.Header
            className="text-center p-3 m-3"
            style={{ textShadow: `0.25rem 0.25rem #412456` }}
          >
            <h3>Access Profile</h3>
          </Card.Header>
          <Card.Body>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" ref={emailRef} required />
            </Form.Group>
            <Form.Group id="password">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" ref={passwordRef} required />
            </Form.Group>

            <div className="w-100 text-center mt-3 AUTH">
              <Link to="/authorise-reset">Forgot Password?</Link>
            </div>
          </Card.Body>
          <Card.Footer className="m-0 p-0">
            <Button disabled={loading} className="w-100" type="submit">
              Let Me In
            </Button>
          </Card.Footer>
        </Form>
      </Card>

      <div className="w-100 text-center mt-3 AUTH">
        <p>
          {" "}
          No Profile? <Link to="/authorise-new">Create One Here</Link>
        </p>
      </div>
      <div className="w-100 text-center mt-2">
        <button className="login-modal-button" onClick={openModal}>
          Why do I need a profile?
        </button>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        ariaHideApp={false}
      >
        <div className="modal-content">
          <div className="close-modal">
            <button onClick={closeModal}>X</button>
          </div>
          <div className="login-modal-view">
            <div className="login-modal-data">
              <p>
                A personal profile is how we keep all the elements of your
                purchase together.
              </p>

              <p>
                Initially, all you need to provide is an email address and a
                first name. That's all the information we retain.
              </p>
              <p>
                If you choose to ask any specific questions as part of your
                reading, they are linked to your profile to speed up the reading
                process.{" "}
              </p>
              <p>
                The only emails you'll ever receive from Ask The Tarot will be
                related specifically to a reading you've purchased - either to
                deliver it, or to let you know when to expect it.
              </p>
            </div>
          </div>
          <div className="login-close-modal">
            <button onClick={closeModal}>Continue To Profile</button>
          </div>
        </div>
      </Modal>
    </>
  )
}
